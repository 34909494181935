@import './global/variable', './global/new-variable';

html {
  /* Works on Chrome, Edge, and Safari */
  & ::-webkit-scrollbar {
    width: 0px;
  }
}

a:hover {
  text-decoration: none !important;
}

.is-light-text,
p {
  /* color: rgb(193, 204, 222); */
  color: $primary-text-color;
  font-weight: 200;
}

/* font weights */

.font-medium {
  font-family: $semi-bold-font;
}

.font-bold {
  font-family: $bold-font;
}

.btn-primary {
  border-color: $primary-border-color !important;
}

/* text sizes */
.text-x-large {
  font-size: 2rem;
}

.text-large {
  font-size: 1.25rem;
}

.text-medium {
  font-size: 1.125rem;
}

.text-small {
  font-size: 0.875rem;
}

.text-x-small {
  font-size: 0.75rem;
}

/* letter spacing */
.letter-spacing {
  letter-spacing: 0.05rem;
}

body {
  position: relative;
  background: $primary-theme-color !important;
  color: $default-text-color;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 100 !important;
  line-height: 1.5;
}

.container-fluid {
  width: 100% !important;
}

.wrapper {
  margin-left: 104.8px;
}
.navbar {
  padding: 0 0 !important;
}

.selecteddriver {
  border-left: 4px solid $primary-border-color;
  background: $primary-theme-color;
  cursor: pointer;
}

.driver-row {
  cursor: pointer;
}

/* navbar styles */
.is-white {
  background: $primary-text-color;
  height: 65px;
}

.is-dark {
  background: $primary-bg-color;
  height: 60px;
}

/* user image style */
.img-container > img {
  width: 45px;
}

.grid-card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1.5rem;
  height: calc(100%);
}

.chart-card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1.5rem 1.5rem 0.75rem 1.5rem;
  height: calc(100%);
}

.card-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  min-height: 30px;
}

.is-card-dark {
  transition: transform 350ms ease-in-out;
  background: $primary-bg-color !important;
}

.logo-login {
  width: 150px;
}

.logo {
  width: 150px;
}

.fab {
  color: $primary-text-color;
  opacity: 0.6;
}
.opacity-disabled{
  opacity: 0.5;
  cursor: not-allowed !important;
}
.overflow-visible{
  overflow: visible !important;
}

/* media queries */
@media screen and (min-width: 701px) {
  .fixed-bottom {
    visibility: hidden;
  }
  .mobile-only {
    visibility: hidden;
  }
}

/* media queries */
@media screen and (max-width: 991px) {
  .container-fluid.pr-5,
  .container-fluid.pl-5 {
    width: 90% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 991px) {
  .full-height {
    height: 100% !important;
  }
}

@media screen and (max-width: 567px) {
  .full-height.border-left,
  .full-height.border-right {
    border-left: none !important;
    border-right: none !important;
  }

  .large {
    height: 350px !important;
  }
}

.ham-menu {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.input-group,
.form-group {
  margin-bottom: 10px;
  position: relative;
}

.btn-round {
  border-width: 1px !important;
  border-radius: 30px !important;
  background-color: var(--secondary-btn-bg-color);
  color: $primary-text-color !important;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-group.no-border .input-group-prepend .input-group-text,
.form-group.no-border .input-group-append .input-group-text,
.input-group.no-border .input-group-prepend .input-group-text,
.input-group.no-border .input-group-append .input-group-text {
  padding: 11px 0 11px 19px;
}

.form-group .form-control,
.input-group .form-control {
  padding: 10px 18px 10px 18px;
}

.card .card-header .card-title {
  color: $primary-text-color;
  font-weight: 100 !important;
}

.card-body {
  padding: 0em !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

footer {
  padding-top: 50px;
  color: $primary-text-color;
  font-weight: 100 !important;
}

.table-hover tbody tr:hover,
.table {
  color: $primary-text-color !important;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-color: $ternary-border-color !important;
  padding: 12px 7px;
  vertical-align: middle;
}

.video-annotation-table .table > tbody > tr {
  height: 60px;
}

.stats-link {
  color: $primary-text-color;
  background-color: var(--primary-bg-color);
  text-decoration: none;
  cursor: hand;
  &:hover {
    color: $primary-text-color;

    .is-card-dark {
      transform: scale(1.06);
    }
  }
}

.btn-link {
  border: 0;
  box-shadow: none;
  background: transparent;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  user-select: none;
}
.btn-link:hover {
  text-decoration: none !important;
}
.form-control {
  background-color: $input-bg-color !important;
  height: calc(2.25rem + 2px) !important;
  padding: 0.5rem 0.7rem !important;
  color: $input-text-color !important;
  border: 1px solid $input-border-color !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  font-size: 0.8rem !important;
  outline: none;
}


.form-check-input {
  width: 20px;
  height: 20px;
  border: 0.07em solid $input-border-color;
  background-color: $input-bg-color;
  margin: 0;
  padding: 0;
}

.form-group .form-control,
.input-group .form-control {
  padding: 10px 18px 10px 18px;
}

.card .form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: $input-bg-color !important;
  cursor: not-allowed !important;
}

.card label {
  color: $label-text-color;
  font-size: 0.8rem;
}

.card form label + .form-control {
  margin-bottom: 20px;
}

.card > hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid $divider-border-color !important;
}

.btn-primary {
  background-color: var(--secondary-btn-bg-color) !important;
  color: $btn-text-color !important;
  border-color: var(--secondary-btn-bg-color) !important;
  margin-left: 10px;
}
.link-btn{
  background: none;
  border: none;
  text-decoration: underline;
  color: #006BD6;
  font-weight: bold;
}
.small-btn{
  background: #071540;
  color: white;
  border: 1px solid;
  border-radius: 20px;
  font-size: 11px;
  padding: 4px 10px;
}
.card .btn-white {
  background-color: transparent;
  border-color: var(--secondary-btn-bg-color);
  color: var(--secondary-btn-bg-color);
}

.btn {
  padding: 6px 12px !important;
}

.card .author {
  text-align: center;
  text-transform: none;
  margin-top: 25px;
}

.card .avatar {
  width: 124px;
  height: 124px;
  border: 5px solid $ternary-border-color;
  border-bottom-color: transparent;
  background-color: transparent;
  position: relative;
  border-radius: 50%;
  margin-bottom: 15px;
}

.ht-450 {
  height: 450px;
}

.score {
  font-size: 80px;
  text-align: center;
  text-transform: none;
  margin-top: 25px;
  color: $primary-text-color !important;
  opacity: 0.7;
  line-height: 1;
}

.score-per {
  text-align: center;
  text-transform: none;
  margin-top: 25px;
  color: $primary-text-color !important;
  opacity: 0.7;
}

.modal-content {
  background-color: $popup-bg-color !important;
}

.modal-header {
  border-bottom: 0px solid $popup-section-border-color !important;
}

.modal-footer {
  border-top: 0px solid $popup-section-border-color !important;
}

.modal p {
  font-size: 15px;
}
.selectImg {
  cursor: pointer;
}
.table-row {
  cursor: pointer;
}
.card {
  border: 0px !important;
}

.time-filter > ul > li {
  display: inline-block;
  margin-left: 5px;
}

.time-filter > ul > li.active {
  display: inline-block;
  background-color: var(--secondary-btn-bg-color);
  & > a {
    color: $white;
  }
}

.time-filter > ul > li > a {
  padding: 2px 7px;
  border: 1px solid var(--secondary-btn-bg-color);
  font-size: 12px;
  line-height: 22px;
  color: var(--primary-text-color);
  position: relative;
  text-decoration: none;
}

.score-card-per-km > h3 {
  font-weight: 100;
  color: $primary-text-color;
}

@media screen and (max-width: 567px) {
  .wrapper {
    margin-left: 0px;
  }

  .user-detail {
    display: none;
  }
}

.modal-xxl {
  width: 100% !important;
  max-width: 1020px !important;
}

.dbai-tip {
  background-color: $quaternary-bg-color;
  padding: 20px;
  text-align: center;
}
.dbai-tip .highlighter {
  color: $secondary-text-color;
  font-size: 25px;
}
.timeline-label {
  color: #7fedff;
}
.fsi-label {
  color: #fffc;
}
.fsi-config {
  color: $secondary-text-color;
  position: fixed;
  bottom: 20px;
  margin-right: 20px;

  right: 0;
}

.scrollable-div {
  height: 450px;
  overflow-y: auto;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.scrollable-div {
  overflow-y: hidden;
}
.scrollable-div:hover {
  overflow-y: scroll;
}

.custom-seekbar {
  cursor: pointer;
  height: 3px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  background-color: #A9A9A9;
}
.custom-seekbar span {
  background-color: $ternary-bg-color;
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 0px;
}

img .rounded-circle:hover {
  cursor: pointer;
}

.btnTrend {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.btnMap {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $primary-text-color;
  text-align: center;
  white-space: nowrap;
  background-color: $progress-bar-bg-color;
  transition: width 0.6s ease;
}
.progress {
  border-radius: 0.875rem;
  background-color: $secondary-bg-color !important;
}

.icon-calender .SingleDatePicker_picker {
  transform: scale(0.7);
  right: -2.42em !important;
  top: 0 !important;
}

.icon-calender .DateInput,
.icon-calender .DateInput_input__small {
  display: none;
}

.icon-calender .SingleDatePickerInput {
  background-color: transparent;
}

.statistic .statistic_data {
  width: 100%;
  height: 18vh;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.statistic .statistic_data .statistic_count {
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.statistic .statistic_data .statistic_title {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: $secondary-text-color;
}

/*! ========================================================================
 * Bootstrap Toggle: bootstrap2-toggle.css v2.2.0
 * http://www.bootstraptoggle.com
 * ========================================================================
 * Copyright 2014 Min Hur, The New York Times Company
 * Licensed under MIT
 * ======================================================================== */

label.checkbox .toggle,
label.checkbox.inline .toggle {
  margin-left: -20px;
  margin-right: 5px;
}

.toggle {
  min-width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.toggle-group {
  position: absolute;
  width: 200%;
  height: 60px;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: $primary-theme-color !important;
}

.toggle.off .toggle-group {
  left: -100%;
}

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-handle.btn-mini {
  top: -2px;
}

.toggle-handle.btn-xs {
  top: -2px;
}

.toggle.btn {
  min-width: 30px;
}
.toggle-on.btn {
  padding-right: 24px;
  display: flex;
  align-items: center;
}
.toggle-off.btn {
  padding-left: 24px;
  display: flex;
  align-items: center;
}

.toggle.btn-large {
  min-width: 40px;
}
.toggle-on.btn-large {
  padding-right: 35px;
}
.toggle-off.btn-large {
  padding-left: 35px;
}

.toggle.btn-lg {
  min-width: 40px;
}
.toggle-on.btn-lg {
  padding-right: 35px;
}
.toggle-off.btn-lg {
  padding-left: 35px;
}

.toggle.btn-small {
  min-width: 25px;
}
.toggle-on.btn-small {
  padding-right: 20px;
}
.toggle-off.btn-small {
  padding-left: 20px;
}

.toggle.btn-sm {
  min-width: 25px;
}
.toggle-on.btn-sm {
  padding-right: 20px;
}
.toggle-off.btn-sm {
  padding-left: 20px;
}

.toggle.btn-mini {
  min-width: 20px;
}
.toggle-on.btn-mini {
  padding-right: 12px;
}
.toggle-off.btn-mini {
  padding-left: 12px;
}

.toggle.btn-xs {
  min-width: 20px;
}
.toggle-on.btn-xs {
  padding-right: 12px;
}
.toggle-off.btn-xs {
  padding-left: 12px;
}

.slow .toggle-group {
  transition: left 0.7s;
  -webkit-transition: left 0.7s;
}
.fast .toggle-group {
  transition: left 0.1s;
  -webkit-transition: left 0.1s;
}
.quick .toggle-group {
  transition: none;
  -webkit-transition: none;
}

.toggle-on[disabled],
.toggle-on.disabled {
  cursor: not-allowed !important;
}
.toggle-off[disabled],
.toggle-on.disabled {
  cursor: not-allowed !important;
}

.toggle-handle {
  position: relative !important;
  margin: 0 auto !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 100% !important;
  width: 0px !important;
  border-width: 0 1px !important;
}

.toggle-off.btn {
  padding-left: 24px !important;
}

.recent-events {
  height: 430px;
  overflow: auto;
}

.link {
  color: $secondary-text-color;
}

.heart-icon {
  width: 1.14em;
  height: 1.14em;
  margin: 0 0.32em;
}

.video-player-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
}

.video-placeholder {
  border: 0.07em solid $placeholder-border-color;
  background-color: $placeholder-background-color;
  display: flex;
  justify-content: center;
}

.video-placeholder img {
  width: 60px;
  filter: grayscale(100%);
}

.video-placeholder:hover img {
  filter: none;
  opacity: 1;
  cursor: pointer;
}

.fas,
.fa {
  cursor: pointer;
}

.annotate-video-wrapper {
  width: 80%;
  height: 70vh;
  margin: auto;
}

.table-video-wrapper {
  height: 18.42em;
  width: 32.71em;
  margin: 1em auto;
}

select.form-control option {
  background-color: $primary-bg-color;
  color: $primary-text-color;
}

.device-status-tag {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
  right: 17px;
  bottom: 2px;
}
.Online {
  background-color: $online-color;
}
.Offline {
  background-color: $offline-color;
}
.Inactive {
  background-color: $inactive-color;
}
.nav-bar .loader {
  position: fixed;
}

.tracking-list {
  height: 56vh;
  overflow: auto;
}

.eventTitle {
  font-weight: 400 !important;
}

.eventLocation {
  color: $primary-descriptor-text-color;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.hvh-85 {
  height: 85vh;
}

.marker {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $map-marker-bg-color;
}

.error-text {
  color: $warning-color !important;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.orange-border {
  border: solid 1px $warning-color !important;
}

.table-row-bg {
  background: $secondary-bg-color;
}
.secondary-text{
  color:  var(--checked-checkbox)
}

.Toastify__toast-container--top-right {
  top: 4em !important;
}

.search-bar {
  background-color: $search-field-bg-color;
  border-radius: 4px;
  outline: none;
  border: 1px solid $search-field-border-color;
}
.search-input {
  background-color: $search-field-bg-color;
  color: $search-field-text-color;
  outline: none;
  border: 0px;
}
.fa {
  color: $secondary-btn-bg-color;
}

.score-up {
  color: $green;
}

.score-down {
  color: $red;
}

.no-hover-effect:hover {
  cursor: auto;
  background-color: transparent !important;
}

.user-option-menu {
  display: flex;
  flex-direction: row;
  min-width: 100px;

  svg {
    font-size: 22px;
  }

  span {
    margin-left: 16px;
    font-size: 14px;
  }
}

.modern-card{
  box-shadow: 0px 2px 20px #00000026;
  border-radius: 12px;
  background: $primary-bg-color;
  overflow: auto;
}

@media screen and (max-width: 480px) {
  .user-option-menu {
    min-width: 75px;
  }
}

.filter-bg {
  background: $primary-bg-color;
  border-bottom: 1px solid $primary-theme-color;
}

.eventTitle {
  font-weight: 400 !important;
}

.eventLocation {
  color: #ccd1d5;
  color: var(--primary-descriptior-text);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.MuiSvgIcon-root {
  cursor: pointer;
}

.scrollable-component {
  width: 100%;

  & > * {
    margin: 0 2%;
  }

  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-bg-color);

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--secondary-bg-color);
    border-radius: 20px;
  }
}

.border-right {
  border-right: 1px solid var(--secondary-border-color);
}

.border-bottom {
  border-bottom: 1px solid var(--secondary-border-color);
}

.bg {
  background: var(--primary-bg-color);
}

textarea.form-control {
  height: auto !important;
}

.modal-divider {
  color: var(--primary-descriptior-text);
  background-color: var(--primary-descriptior-text);
  opacity: 0.4
}

.accordian-dropdown-icon {
  color: var(--primary-descriptior-text) !important;
}

.form-control-2 {
  background-color: $input-bg-color;
  height: calc(2.25rem + 2px);
  padding: 0.5rem 0.7rem;
  color: black !important;
  border: 1px solid $input-border-color;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  font-size: 0.8rem;
  outline: none;
  width: 100%;
  border-radius: 5px;
}

.tat{
  gap: 10px;
  .tat-icon-red{
      color : #e75757
  }
  .tat-icon-green{
      color : #0080009c
  }
  .tat-subtext{
    font-size: 10px;
    padding: 0px;
    margin: 0px;
  }
}

.clock{
  gap: 2px;
  padding: 0px;
  margin: 0px;
  padding-left: 3px;
  .clock-section{
      gap: 2px;
      padding: 0px;
      margin: 0px;
      font-size: 12px;
  }
  .clock-block{
      background: gainsboro;
      border-radius: 3px;
      color: black;
      font-size: 12px;
      padding:0px 2px;
      min-width: 15px;
  }
  .clock-text{
      font-size: 8px;
      padding: 0px;
      margin: 0px;
  }
  .tat-subtext{
    font-size: 8px;

  }
}

.bottom-tooltip{
  position:relative;                 

}
.bottom-tooltip:before{
  content: attr(data-text);
  font-size: 12px !important; 
  opacity: 1 !important;
  font-weight: 100;
  position:absolute;
  transform:translateY(-50%);                    
  top:0%;
  left: -100%;
  margin-top:70px;                    
  width:100px;
  padding:5px 10px ;
  border-radius:8px;
  background:#000;
  color: #fff;
  text-align:center;
  display:none; 
}
.event-tooltip{
  i{
    color: var(--primary-text-color);
  }
}
.event-tooltip:before{
  z-index: 300;
  margin-top:65px;                    


}
.bottom-tooltip:after {
  content: "";
  position:absolute;                    
  margin-top:10px;                   
  top:100%;
  right: 50%;
  // transform: rotate(180deg);
  transform: translateY(-100%) rotate(90deg) ;

  // transform:;
  border:10px solid #000;
  border-color: transparent black transparent transparent;
  display:none;
}
.event-tooltip:after{

  left : 50%;

}
.bottom-tooltip:hover:before, .bottom-tooltip:hover:after {
  display:block;
}

    .right-tooltip{
                            position:relative;                 
                            span{
                                font-size: 10px !important;
                                font-weight: 600;
                                opacity: 0.6;
                            }
                          
                        }
                        .right-tooltip:before{
                            content: attr(data-text);
                            font-size: 12px !important; 
                            opacity: 1 !important;
                            font-weight: 100;
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);                    
                            left:100%;
                            margin-left:15px;                    
                            width:150px;
                            padding:5px 10px ;
                            border-radius:8px;
                            background:#000;
                            color: #fff;
                            text-align:center;
                            display:none; 
                        }
                        .right-tooltip:after {
                            content: "";
                            position:absolute;                    
                            left:100%;
                            margin-left:-3px;                   
                            top:50%;
                            transform:translateY(-50%);
                            border:10px solid #000;
                            border-color: transparent black transparent transparent;
                            display:none;
                          }
                        .right-tooltip:hover:before, .right-tooltip:hover:after {
                            display:block;
                          }