@import '../../../styles/global/new-variable', '../../../styles/global/variable';

:root {
  --toastify-color-light: var(--primary-bg-color) !important;
  --toastify-text-color-light: var(--primary-text-color) !important;
  --toastify-color-error: #{$warning-color} !important;
}

$root: '.notification';

#{$root} {
  .Toastify__toast-body {
    align-items: flex-start;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  .Toastify__close-button--light {
    color: var(--primary-text-color) !important;
  }
  .Toastify__progress-bar {
    height: 2px;
  }
}
