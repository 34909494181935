$maastricht-blue: #08293c;
$downriver: #071540;
$light-downriver: #071540eb;
$ateneo-blue: #01405f;
$white: #ffffff;
$dark-grey: #a9a9a9;
$grey: #d2d2d6;
$off-white: #f2f2f2;
$persimmon: #f06200;
$grey: #d6d6d6;
$black: 0, 0, 0;
$rgb-white: 255, 255, 255;
$red: #e75757;
$yello: #ffcc00;

:root {
  --primary-theme-color: #{$maastricht-blue};

  --primary-bg-color: #{$ateneo-blue};
  --secondary-bg-color: #{$off-white};
  --ternary-bg-color: #{$persimmon};
  --side-nav-bg-color: #{$ateneo-blue};
  --avatar-bg-color: #{$maastricht-blue};
  --datepicker-ele-bg-color: #{$ateneo-blue};
  --auth-container-bg-color: #{$downriver};
  --auth-container-input-bg-color: #{$off-white};

  --primary-text-color: #{$white};
  --secondary-text-color: #{$dark-grey};
  --ternery-text-color: #{$downriver};

  --auth-container-primary-text-color: #{$downriver};
  --auth-container-secondary-text-color: #{$white};

  --primary-btn-text-color: #{$dark-grey};

  --secondary-btn-bg-color: #{$persimmon};
  --auth-container-btn-bg-color: #{$downriver};

  --side-nav-hover-color: #{$maastricht-blue};

  --primary-border-color: #{$grey};
  --secondary-border-color: #{$maastricht-blue};
  --avatar-border-color: #{$maastricht-blue};
  --side-nav-border-color: #{$persimmon};

  --primary-hover-color: rgba(#{$black, 0.06});
}

:root[data-theme='light'] {
  --primary-theme-color: #{$off-white};

  --primary-bg-color: #{$white};
  --secondary-bg-color: #{$downriver};
  --ternary-bg-color: #{$downriver};
  --side-nav-bg-color: #{$downriver};
  --avatar-bg-color: #{$downriver};
  --datepicker-ele-bg-color: #{$downriver};
  --auth-container-bg-color: #{$downriver};
  --auth-container-input-bg-color: #{$off-white};

  --primary-text-color: #{$downriver};
  --secondary-text-color: #{$dark-grey};
  --ternery-text-color: #{$white};

  --auth-container-primary-text-color: #{$downriver};
  --auth-container-secondary-text-color: #{$white};

  --primary-btn-text-color: #{$dark-grey};

  --secondary-btn-bg-color: #{$downriver};
  --auth-container-btn-bg-color: #{$downriver};

  --side-nav-hover-color: rgba(#{$rgb-white}, 0.06);

  --primary-border-color: #{$downriver};
  --secondary-border-color: #{$grey};
  --avatar-border-color: #{$downriver};
  --side-nav-border-color: #{$persimmon};

  --primary-hover-color: rgba(#{$black, 0.06});
}
