$root: '.loader';

#{$root} {
  //position of beatloader
  position: absolute;
  width: 100%;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &_inner {
    display: flex;
    justify-content: center;
  }

  //overlay of spinner
  &_overlay-div {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.3;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  //style to design spinner
  .ball-beat > div {
    background-color: #fff;
    width: 1.14em;
    height: 1.14em;
    border-radius: 100%;
    margin: 0.14em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    -webkit-animation: ball-beat 0.7s 0s infinite linear;
    animation: ball-beat 0.7s 0s infinite linear;
  }
  .ball-beat > div:nth-child(2n-1) {
    -webkit-animation-delay: -0.35s !important;
    animation-delay: -0.35s !important;
  }

  @keyframes ball-beat {
    50% {
      opacity: 0.2;
      -webkit-transform: scale(0.75);
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
