:root {
  --primary-theme: 8, 41, 60;
  --primary-bg: #01405f;
  --checked-checkbox:#f06200;
  --primary-card-bg: #ffffff;
  --primary-dropzon-bg: #cacaca;
  --default-bg: 255, 255, 255;
  --secondary-bg: 0, 0, 0;
  --default-text: #525f7f;
  --notification-bg: #166794;
  --quaternary-bg: #333438;
  --primary-descriptior-text: #ccd1d5;
  --progress-bg: #e14eca;
  --secondary-theme: #f06200;
  --online: #54a001;
  --offline: #e6b100;
  --inactive: grey;
  --warning: #f6ebe8;
  --green: #79ea86;
  --red: #e75757;
  --text: #ffffff;
  --shadow: #071540;
  --text-badge-bg: #022230;
  --info-container-bg: #08293c;
  --back-bg: #09293B;
}

:root[data-theme='light'] {
  --shadow: #cacaca;
  --primary-theme: 242, 242, 242;
  --secondary-theme: #f06200;
  --checked-checkbox: #071540;
  --default-bg: 0, 0, 0;
  --primary-bg: #ffffff;
  --primary-card-bg: #cacaca;
  --primary-dropzon-bg: #cacaca;
  --secondary-bg: 0, 0, 0;
  --notification-bg: #fefefe;
  --quaternary-bg: #333438;
  --default-text: #525f7f;
  --primary-descriptior-text: #525252;
  --progress-bg: #e14eca;
  --online: #54a001;
  --offline: #e6b100;
  --inactive: grey;
  --warning: #f6ebe8;
  --green: #79ea86;
  --red: #e75757;
  --text: #ffffff;
  --text-badge-bg: rgb(185, 185, 185);
  --info-container-bg: #ECF0FF;
  --back-bg: #F2F2F2;

}

/* default theme color */
$primary-theme-color: rgba(var(--primary-theme), 1);

/* text color */
$default-text-color: var(--default-text);
$primary-text-color: rgba(var(--default-bg), 1);
$secondary-text-color: var(--secondary-theme);
$primary-descriptor-text-color: var(--primary-descriptior-text); // description text color
$input-text-color: rgba(var(--default-bg), 0.8); // input field text color
$label-text-color: rgba(var(--default-bg), 0.6); // label text color
$search-field-text-color: var(--primary-descriptior-text); // search field text color
$btn-text-color: var(--text);

/* background color */
$default-bg-color: rgba(var(--default-bg), 1);
$primary-bg-color: var(--primary-bg);
$secondary-bg-color: rgba(var(--secondary-bg), 0.03);
$ternary-bg-color: var(--secondary-theme);
$quaternary-bg-color: var(--quaternary-bg);
$progress-bar-bg-color: var(--progress-bg); // progress bar background color
$input-bg-color: rgba(var(--secondary-bg), 0.07); // input field background color
$popup-bg-color: var(--primary-bg); // popup background color
$map-marker-bg-color: var(--secondary-theme); // map marker background color
$primary-onhover-bg-color: rgba(var(--primary-bg), 0.75); // hover background color
$placeholder-background-color: rbga(
  var(--secondary-bg),
  0.07
); // placeholder container background color
$search-field-bg-color: rgba(var(--primary-theme), 1); // search-field  background color
$notification-bg-color: var(--notification-bg);
$dropdown-item-hover-bg-color: rgba(var(--secondary-bg), 0.19);
$info-container-bg-color: rgba(var(--info-container-bg),1);

/* button background color */
$primary-btn-bg-color: var(--secondary-theme);
$secondary-btn-bg-color: var(--primary-descriptior-text);

/* border color */
$primary-border-color: var(--secondary-theme);
$secondary-border-color: var(--primary-descriptior-text);
$ternary-border-color: rgba(var(--primary-theme), 0.1);
$input-border-color: rgba(var(--default-bg), 0.1); // input field border color
$placeholder-border-color: rgba(var(--default-bg), 0.1); // placeholder container border color
$divider-border-color: rgba(var(--default-bg), 0.5); // divider border color
$popup-section-border-color: var(--primary-descriptior-text);
$search-field-border-color: rgba(var(--default-bg), 0.1); // search field border color

/* status color */
$warning-color: var(--secondary-theme);
$online-color: var(--online);
$offline-color: var(--offline);
$inactive-color: var(--inactive);
$warning-bg-color: var(--warning);

/* scroll bar element color*/
$scroll-box-shadow-color: rgba(var(--secondary-bg), 0.3);
$scroll-thumb-bg-color: var(--primary-bg);
$scroll-thumb-border-color: var(--primary-bg);

/* Driver score color */
$green: var(--green);
$red: var(--red);

/* Typography */
$regular-font: 'basefont-regular';
$semi-bold-font: 'basefont-medium';
$bold-font: 'basefont-bold';
